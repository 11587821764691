<template>
  <UContainer class="flex flex-col gap-10">
    <HomeSlide />
    <template v-for="block in blocksItems" :key="block.id">
      <component :is="block.content" v-bind="block.props" />
    </template>
  </UContainer>
</template>

<script lang="ts" setup>
import { take } from "@techmely/utils";
import { defaultSeoOgImage } from "features/seo";
import { typesafeI18n } from "~/locales/i18n-vue";
import CoursesPopular from "~/components/Home/blocks/CoursesPopular.vue";
import InterviewQuestions from "~/components/Home/blocks/InterviewQuestions.vue";
import KungfuPost from "~/components/Home/blocks/KungfuPost.vue";
import JavascriptCourse from "~/components/Home/blocks/JavascriptCourse.vue";
import Testimonials from "~/components/Home/blocks/Testimonials.vue";
import {
  getPostTrending,
  getPosts,
  getTopAuthors,
  getTopicPopulars,
} from "~/features/post";
import { getCountQuestions } from "~/features/question";

definePageMeta({
  layout: "page",
});
const DISPLAY_LATEST_NEWS = 5;
const DISPLAY_TAG_ITEMS = 10;
const DISPLAY_AUTHOR_ITEMS = 3;
const FALLBACK_QUESTION_COUNT = 5000;

const { LL } = typesafeI18n();
const [
  postsTrending,
  posts,
  topicPopulars,
  topAuthors,
  questionsCount,
] = await Promise.all([
  getPostTrending(),
  getPosts({ page: 1, size: 5 }),
  getTopicPopulars(),
  getTopAuthors(),
  getCountQuestions(),
]);

const blocksItems = computed(() => [
  {
    id: "courses-popular",
    content: CoursesPopular,
  },
  {
    id: "interview-question",
    content: InterviewQuestions,
    props: {
      questionsCount: questionsCount.data.value || FALLBACK_QUESTION_COUNT,
    },
  },
  {
    id: "posts",
    content: KungfuPost,
    props: {
      trending: postsTrending.data?.value?.data || [],
      latest: take(posts.data.value?.items || [], DISPLAY_LATEST_NEWS),
      tags: topicPopulars.data.value?.data,
      authors: take(topAuthors.data.value?.data || [], DISPLAY_AUTHOR_ITEMS),
    },
  },
  {
    id: "melydemy",
    content: JavascriptCourse,
  },
  {
    id: "testimonial",
    content: Testimonials,
  },
]);

const title = LL.value.seo_home_title();
const description = LL.value.seo_home_description();
const meta: SeoMeta = {
  title,
  description,
  ...defaultSeoOgImage,
};

useSeoMeta(meta);
</script>
